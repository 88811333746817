<template>
  <div class="home">
    <el-main>
      <el-container>
        <div class="container">
          <h1 class="title">Privateautoquote Insurance Advisor SMS/MMS Terms of Service</h1>
          <p>
            1. When users opt-in they can expect periodic text messages
            regarding insurance products and opportunities available in their
            geographic area.
          </p>
          <p>
            2. You can cancel the short code service at any time. Just text
            "STOP" to the short code. After you send the short code message
            "STOP" to us, we will send you an short code message to confirm that
            you have been unsubscribed. After this, you will no longer receive
            short code messages from us. If you want to join again, just sign up
            as you did the first time and we will start sending short code
            messages to you again.
          </p>
          <p>
            3. Data obtained through the short code program will not be shared
            with any third-parties for their marketing purposes.
          </p>
          <p>
            4. If you are experiencing issues with the messaging program you can
            reply with the keyword HELP for more assistance, or you can get help
            directly at
            <a target="_blank" href="mailto:media@privateautoquote.com">media@privateautoquote.com</a>
          </p>
          <p>5. Carriers are not liable for delayed or undelivered messages.</p>
          <p>
            6. As always, message and data rates may apply for any messages sent
            to you from us and to us from you. Message frequency varies. If you
            have any questions about your text plan or data plan, it is best to
            contact your wireless provider.
          </p>
          <p>
            7. If you have any questions regarding privacy, please read our
            privacy policy:
            <a href="/privacy-statement"
              >https://privateautoquote.com/privacy-policy</a
            >
          </p>
        </div>
      </el-container>
    </el-main>
  </div>
</template>

<script>
import {Container} from "element-ui";
Vue.use(Container);
export default {
  name: 'TermsofService',
  components: {
  },
  data () {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss">
@import "../assets/scss/layout.scss";

@import "../assets/scss/text.scss";
</style>
